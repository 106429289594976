import React from "react";
import "./Navbar3.css";
import { Link, useNavigate,NavLink } from "react-router-dom";
import { HiShoppingCart } from "react-icons/hi";
import logo from "../img/AntidoteLogo.svg";
import './loader.css'

const Navbar3 = () => {
  const navigate = useNavigate();

  return (
    <div className="fontss">

    <div className="fontss navi">
      <div className="navv">

          <ul
          
            className="nav"
            style={{
              listStyle: "none",
              display: "flex",
              gap:'2rem'
              //   alignContent: "center",
              //   justifyContent: "center",
            }}
            >
            <li >
              <div className="aa ff" style={{ textDecoration:"none"}}>
                <Link style={{textDecoration:"none"}} to={"/"}>Home</Link>
              </div>
             
            </li>
            <li >
              <a className="aa ff" 
            //    style={}
              >
                <Link to={"/products"}>Products</Link>
              </a>
            </li>
            <li >
              <a className="aa ff">
                <Link to={"/about"}>About Us</Link>
              </a>
            </li>
          </ul>
      
      </div>
      <div>
        <div className="elementor-image img98">

            <img
              width="100%"
         
              src={logo}
              className="attachment-full size-full"
              alt=""
              loading="lazy"
              />
         
        </div>
      </div>
      <div>
        <div className="nonee" onClick={() => navigate("/cart")}>
          <div id="containert">
            <a class="learn-more a11">
              <span class="circle" aria-hidden="true">
                <span class="">
                  <HiShoppingCart
                    size={34}
                    style={{
                        color: "white",
                        marginLeft: "6px",
                        marginTop: " 9px",
                    }}
                  />
                </span>
              </span>
              <span
                style={{
                    fontWeight: "100",
                }}
                class="button-text"
              >
                Cart(0)
              </span>
            </a>
          </div>
        </div>
      </div>
   
    </div>
    <div className="mediaQ-nav">
    <div className="elementor-image">
     
            <img
            
            style={{
              // width: "40%",
                padding: "11px",
                display:'none'
            }}
              src={logo}
              className="attachment-full size-full"
              alt=""
              loading="lazy"
              />
         
        </div>
    <div className="mobile-menu1" >
    <div style={{display: "flex",
    justifyContent: "space-between",
    alignItems: "center"}}>
        <div>
       
        </div>
        <div>
        <div style={{display:'flex'}} className="">
        <div class="nav1">
          <input type="checkbox" />
          <span className="xx">
          </span>
          <span className="xx">
          </span>
          <span className="xx"></span>
          <div class="menu1">
              
          <li ><NavLink end to="/"  style={({ isActive }) =>
                        isActive
                            ? {
                               display:"none",  
                            }
                            : {}
                    }>Home</NavLink></li>
                       <li ><NavLink end to="/products"  style={({ isActive }) =>
                        isActive
                            ? {
                               display:"none",  
                            }
                            : {}
                    }>Products</NavLink></li>
                       <li ><NavLink end to="/about"  style={({ isActive }) =>
                        isActive
                            ? {
                               display:"none",
                            }
                            : {}
                    }>About Us</NavLink></li>
           
          </div>
        </div>
        <div 
        className="righf"
      style={{marginRight:'8px',marginTop:'9px'}}
      onClick={() => navigate("/cart")}>
    
      
     
        <HiShoppingCart
        size={28}
        
         style={{color:'white'}}
         />
      
    
  </div>
      </div>
        </div>
    </div>

      </div>

    </div>


    <div>

    </div>
   
                </div>
  );
};

export default Navbar3;
