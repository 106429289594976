import React from 'react'
import './Footer.css'
import logo from '../img/AntidoteLogo.svg'
const Footer = () => {
  return (
    <div>
  

<footer class="footer fontss">
  <div class="footer__addr">
    <img class="footer__logo"  src={logo}/> 
        
    {/* <h2>Contact</h2> */}
    
    <address>
      <br/>
          
      {/* <a class="footer__btn" href="mailto:support@antidotecoffee.in">Email Us</a> */}
    </address>
  </div>
  
  {/* <ul class="footer__nav">
    <li class="nav__item">
      <h2 class="nav__title">Media</h2>

      <ul class="nav__ul">
        <li>
          <a href="#">Online</a>
        </li>

        <li>
          <a href="#">Print</a>
        </li>
            
        <li>
          <a href="#">Alternative Ads</a>
        </li>
      </ul>
    </li>
    
    <li class="nav__item nav__item--extra">
      <h2 class="nav__title">Technology</h2>
      
      <ul class="nav__ul nav__ul--extra">
        <li>
          <a href="#">Hardware Design</a>
        </li>
        
        <li>
          <a href="#">Software Design</a>
        </li>
        
        <li>
          <a href="#">Digital Signage</a>
        </li>
        
        <li>
          <a href="#">Automation</a>
        </li>
        
        <li>
          <a href="#">Artificial Intelligence</a>
        </li>
        
        <li>
          <a href="#">IoT</a>
        </li>
      </ul>
    </li>
    
    <li class="nav__item">
      <h2 class="nav__title">Legal</h2>
      
      <ul class="nav__ul">
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        
        <li>
          <a href="#">Terms of Use</a>
        </li>
        
        <li>
          <a href="#">Sitemap</a>
        </li>
      </ul>
    </li>
  </ul> */}
  
  <div class="legal">
    <p>&copy; 2022 Antidote Coffee</p>
    
   
  </div>
</footer>
    </div>
  )
}

export default Footer