import React from 'react'
import Home from './components/Home'
import {BrowserRouter as Router , Routes , Route } from 'react-router-dom'
import './App.css'

import Products from './components/Products'
// import Navbar from './components/Navbar'
import About from './components/About'
import Raspberry from './components/Raspberry'
import Vanilla from './components/Vanilla'
import Espresso from './components/Espresso'
import Irish from './components/Irish'
import Shoppingcart from './components/Shoppingcart'
import Navbar from './components/Navbar'
import CheckOut from './components/CheckOut'
import PaymentCard from './components/PaymentCard'
import Footer from './components/Footer'
import Navbar3 from './components/Navbar3'


const App = () => {
  return (
    
    <Router>
      {/* <Navbar3/> */}
    <Routes> 
      <Route exact path='/' element={<Home/>} />
      <Route exact path='/products' element={<Products/>} />
      <Route exact path='/about' element={<About/>} />
      {/* <Route exact path='/Raspberry' element={<Raspberry/>} /> */}
      {/* <Route exact path='/Vanilla' element={<Vanilla/>} /> */}
      <Route exact path='/Product:id' element={<Espresso/>} />
      {/* <Route exact path='/Irish' element={<Irish/>} /> */}
      <Route exact path='/cart' element={<Shoppingcart/>} />
      <Route exact path='/check' element={<CheckOut/>} />
      {/* <Route exact path='/payment' element={<PaymentCard/>} /> */}
      <Route exact path='/nav3' element={<Navbar3/>} />
      

  
    </Routes>
   {/* <Footer/> */}
  </Router>
  
  )
}

export default App