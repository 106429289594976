import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { DiRequirejs } from "react-icons/di";
import "./Card.scss";
const Card = ({ product }) => {
  const navigate = useNavigate();
  const Name = product.id;
  return (
    <>
      {Name === "Espresso" ? (
        <div className="body fontss">
          <div
            // data-aos="zoom-in-up"
            class="card"
          >
            <div class="imgBox">
              <img src={product.image} alt="mouse corsair" class="mouse" />
            </div>

            <div class="contentBox">
              <h3>{product.Name}</h3>
              <h2 class="price">
                {" "}
                ₹{product.Price}
                <small></small>{" "}
              </h2>
              <Link
                data-aos="zoom-in-up"
                to={{ pathname: `/Product${product.Name}` }}
                class="buy"
              >
                Buy Now
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Name === "Raspberry" ? (
            <div className="fontss">
              <div className="card card4">
                <div className="imgBox">
                  <img
                    src={product.image}
                    alt="mouse corsair"
                    className="mouse"
                  />
                </div>

                <div className="contentBox">
                  <h3>{product.Name}</h3>
                  <h2 className="price">₹{product.Price}</h2>

                  <Link
                    data-aos="zoom-in-up"
                    className="buy"
                    to={{ pathname: `/Product${product.Name}` }}
                    style={{ backgroundColor: "#554750" }}
                  >
                    Buy Now
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <>
              {Name === "Vanilla" ? (
                <div className="fontss">
                  <div class="card card3">
                    <div class="imgBox">
                      <img
                        src={product.image}
                        alt="mouse corsair"
                        class="mouse"
                      />
                    </div>

                    <div class="contentBox">
                      <h3>{product.Name}</h3>
                      <h2 class="price">₹{product.Price}</h2>
                      <Link
                        class="buy"
                        to={{ pathname: `/Product${product.Name}` }}
                        style={{ backgroundColor: "#ddcf8c" }}
                        data-aos="zoom-in-up"
                      >
                        Buy Now
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="fontss">
                  <div className="body">
                    <div class="card card2" style={{}}>
                      <div class="imgBox">
                        <img
                          src={product.image}
                          alt="mouse corsair"
                          class="mouse"
                        />
                      </div>

                      <div class="contentBox">
                        <h3>{product.Name}</h3>
                        <h2 class="price">₹{product.Price}</h2>
                        <Link
                          data-aos="zoom-in-up"
                          to={{ pathname: `/Product${product.Name}` }}
                          class="buy "
                          style={{ backgroundColor: "#f6c38f" }}
                        >
                          Buy Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Card;

// {Statement ===  'Statement' ? (

// ): (

// )}
