import React from 'react'
import Logo from '../img2/AntidoteLogo88.svg'

const Loader = () => {
  return (
    <div className='body55'>
      <section >
      <div className='loader1'>
        <img className='imageLogoLoader' src={Logo} alt="" />
        <div className='pp'>
            
  <p className='p1 p'>A</p>
  <p  className='p2 p'>N</p>
  <p className='p3 p'>T</p>
  <p className='p4 p '>I</p>
  <p className='p5 p'>D</p>
  <p className='p6 p'>O</p>
  <p className='p7 p'>T</p>
  <p className='p7 p'>E</p>

        </div>
</div>

      </section>

    </div>
  )
}

export default Loader