import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";


const app = firebase.initializeApp({
  apiKey: "AIzaSyAyJPnFGBrQLn0_88DGwR2FEigXccfEKWA",
  authDomain: "antidote-28045.firebaseapp.com",
  projectId: "antidote-28045",
  storageBucket: "antidote-28045.appspot.com",
  messagingSenderId: "649513146640",
  appId: "1:649513146640:web:d038c43b193a9c45bf6d95",
  measurementId: "G-N2LZ7F5EEW"
});

export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = app.auth()
export default app

